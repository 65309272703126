import React from 'react'
import { graphql } from 'gatsby'
import Page from './page'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextRendererOptions from '../../contentful/richTextRendererOptions'
// import linkResolver from '../../contentful/linkResolver'

const PageContainer = ({ data, pageContext }) => {
  // console.log(data)
  const { contentfulPage } = data
  const { breadcrumbs } = pageContext

  const page = {
    _doc: contentfulPage,
    locale: contentfulPage.node_locale,
    metaTitle: contentfulPage.metaTitle ? contentfulPage.metaTitle : contentfulPage.title,
    metaDescription: contentfulPage.metaDescription ? contentfulPage.metaDescription : null,
    ogType: 'page',
    heading: contentfulPage.heading ? contentfulPage.heading : contentfulPage.title,
    theme: contentfulPage.theme,
    embed: contentfulPage.embedBlock ? contentfulPage.embedBlock.embedCode.embedCode : null,
    body: contentfulPage.body
      ? documentToReactComponents(contentfulPage.body.json, richTextRendererOptions)
      : null,
    introduction: contentfulPage.introduction
      ? contentfulPage.introduction.childMarkdownRemark.html
      : '',
  }

  return <Page doc={page} crumbs={breadcrumbs} />
}

// function getPageSections(content) {
//   if (content) {
//     return content.map(node => {
//       return {
//         title: node.heading,
//         body: node.body ? documentToReactComponents(node.body.json) : null,
//         slug: node.slug
//       }
//     })
//   }
//   return []
// }

export default PageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      title
      theme
      heading
      embedBlock {
        embedCode {
          embedCode
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      body {
        json
      }
      sections {
        ... on Node {
          ... on ContentfulCalculatorBlock {
            title
          }
          ... on ContentfulQuizBlock {
            id
            contentful_id
            title
            introduction {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      # content {
      #   heading
      #   slug
      #   body {
      #     json
      #   }
      # }
    }
  }
`

import React, { useRef, useState, useEffect } from 'react'
import logotype from '../../../static/sparkalkyl_logo.png'
// import { Link } from 'gatsby'

function Calculator() {
  const refBelopp = useRef(null)
  const refBeloppRange = useRef(null)
  const [belopp, setBelopp] = useState(500)

  const refManadsspar = useRef(null)
  const refManadssparRange = useRef(null)
  const [manadsspar, setManadsspar] = useState(300)

  const refAvkastning = useRef(null)
  const refAvkastningRange = useRef(null)
  const [avkastning, setAvkastning] = useState(5)

  const refSpartid = useRef(null)
  const refSpartidRange = useRef(null)
  const [spartid, setSpartid] = useState(10)

  const [resultat, setResultat] = useState(0)
  const [savings, setSavings] = useState(0)
  const [ariaLive, setAriaLive] = useState(false)

  useEffect(() => {
    setAriaLive(false)
    let baraInsatt = belopp + manadsspar * 12 * spartid

    let arsranta = 0

    /* Ränteutränkning baserad på formel från GR, fi 2017-12-15 */
    let forgaendeAr = (belopp + manadsspar * 12) * (1 + avkastning / 2 / 100)

    for (let i = 1; i <= spartid - 1; i++) {
      arsranta =
        forgaendeAr * (1 + avkastning / 100) +
        manadsspar * 12 * (1 + avkastning / 2 / 100)
      forgaendeAr = arsranta
    }

    let slutsumma = forgaendeAr

    setSavings(isNaN(baraInsatt) ? '-' : baraInsatt)
    setResultat(isNaN(slutsumma) ? '-' : slutsumma)
    setTimeout(() => {
      setAriaLive(true)
    }, 1000)
  }, [belopp, manadsspar, avkastning, spartid])

  return (
    <section className="bg-purple-300 text-white">
      <noscript>
        <div className="max-w-screen-xl mx-auto px-6 py-12 md:py-32">
          <strong>Sparkalkylatorn kräver att du har JavaScript aktiverat i din webbläsare.</strong>
        </div>
      </noscript>
      <div className="max-w-screen-xl mx-auto px-6 py-12 md:py-24 flex flex-col items-center no-js-hide">
        <h2 className="text-4xl mb-6 md:text-6xl md:mb-12 font-bold w-2/3 md:w-1/2" id="sparkalkylatorn" tabIndex="-1">
          <img
            src={logotype}
            alt="Sparkalkylatorn"
            width="600"
            className="w-full"
          />
        </h2>
        <p className="px-8 text-2xl md:text-3xl md:w-1/2 text-center leading-snug">
          Hur mycket kan ett sparande ge&nbsp;dig?
        </p>

        <form className="mt-10 w-full">
          <div className="bg-purple-600 py-8 px-6 arrow-down">
            <div className="py-4 flex flex-wrap w-full md:pr-6">
              <div className="w-full md:w-1/2 flex items-center justify-between md:justify-end">
                <label
                  htmlFor="startbelopp"
                  id="startbelopp-label"
                  className="block text-lg md:text-xl font-bold md:text-right md:w-64"
                >
                  Startbelopp (kr):
                </label>

                <input
                  ref={refBelopp}
                  type="number"
                  value={belopp}
                  min="0"
                  max="100000"
                  onChange={e => {
                    setBelopp(parseInt(refBelopp.current.value))
                  }}
                  onKeyDown={e => {
                    setBelopp(parseInt(refBelopp.current.value))
                  }}
                  id="startbelopp"
                  name="startbelopp"
                  className="w-24 text-xl text-gray-900 p-3 pr-0 ml-6 rounded focus:outline-none focus:shadow-light"
                />
              </div>

              <div className="w-full md:w-1/3 md:pr-6 md:pl-6 flex items-center">
                <input
                  className="w-full form-range"
                  ref={refBeloppRange}
                  type="range"
                  min="0"
                  max="100000"
                  onChange={e => {
                    setBelopp(parseInt(refBeloppRange.current.value))
                  }}
                  value={belopp}
                  aria-labelledby="startbelopp-label"
                  // aria-controls="startbelopp"
                  step="50"
                />
              </div>
            </div>
            <div className="py-4 flex flex-wrap w-full md:pr-6">
              <div className="w-full md:w-1/2 flex items-center justify-between md:justify-end">
                <label
                  htmlFor="manadsspar"
                  id="manadsspar-label"
                  className="block text-lg md:text-xl font-bold md:text-right md:w-64"
                >
                  Månadssparande (kr):
                </label>

                <input
                  ref={refManadsspar}
                  type="number"
                  value={manadsspar}
                  min="0"
                  step="50"
                  max="5000"
                  onChange={e => {
                    setManadsspar(parseInt(refManadsspar.current.value))
                  }}
                  onKeyDown={e => {
                    setManadsspar(parseInt(refManadsspar.current.value))
                  }}
                  id="manadsspar"
                  name="manadsspar"
                  className="w-24 text-xl text-gray-900 p-3 pr-0 ml-6 rounded focus:outline-none focus:shadow-light"
                />
              </div>

              <div className="w-full md:w-1/3 md:pr-6 md:pl-6 flex items-center">
                <input
                  className="w-full form-range"
                  ref={refManadssparRange}
                  type="range"
                  min="0"
                  step="50"
                  max="5000"
                  onChange={e => {
                    setManadsspar(parseInt(refManadssparRange.current.value))
                  }}
                  value={manadsspar}
                  aria-labelledby="manadsspar-label"
                  // aria-controls="manadsspar"
                />
              </div>
            </div>

            <div className="py-4 flex flex-wrap w-full md:pr-6">
              <div className="w-full md:w-1/2 flex items-center justify-between md:justify-end">
                <label
                  htmlFor="avkastning"
                  id="avkastning-label"
                  className="block text-lg md:text-xl font-bold md:text-right md:w-64"
                >
                  Avkastning per år (%):
                </label>

                <input
                  ref={refAvkastning}
                  type="number"
                  step=".1"
                  min="0"
                  max="15"
                  onChange={e => {
                    setAvkastning(parseFloat(refAvkastning.current.value))
                  }}
                  onKeyDown={e => {
                    setAvkastning(parseFloat(refAvkastning.current.value))
                  }}
                  value={avkastning}
                  id="avkastning"
                  name="avkastning"
                  className="w-24 text-xl text-gray-900 p-3 pr-0 ml-6 rounded focus:outline-none focus:shadow-light"
                />
              </div>

              <div className="w-full md:w-1/3 md:pr-6 md:pl-6 flex items-center">
                <input
                  className="w-full"
                  ref={refAvkastningRange}
                  type="range"
                  min="0"
                  max="15"
                  step=".1"
                  onChange={e => {
                    setAvkastning(parseFloat(refAvkastningRange.current.value))
                  }}
                  value={avkastning}
                  aria-labelledby="avkastning-label"
                  // aria-controls="avkastning"
                />
              </div>
            </div>

            <div className="py-4 flex flex-wrap w-full md:pr-6">
              <div className="w-full md:w-1/2 flex items-center justify-between md:justify-end">
                <label
                  htmlFor="spartid"
                  id="spartid-label"
                  className="block text-lg md:text-xl font-bold md:text-right md:w-64"
                >
                  Spartid (år):
                </label>

                <input
                  ref={refSpartid}
                  type="number"
                  value={spartid}
                  min="0"
                  max="50"
                  maxLength="6"
                  step="1"
                  onChange={e => {
                    setSpartid(parseInt(refSpartid.current.value))
                  }}
                  onKeyDown={e => {
                    setSpartid(parseInt(refSpartid.current.value))
                  }}
                  id="spartid"
                  name="spartid"
                  className="w-24 text-xl text-gray-900 p-3 pr-0 ml-6 rounded focus:outline-none focus:shadow-light"
                />
              </div>

              <div className="w-full md:w-1/3 md:pr-6 md:pl-6 flex items-center">
                <input
                  className="w-full form-range"
                  ref={refSpartidRange}
                  type="range"
                  min="0"
                  max="50"
                  step="1"
                  onChange={e => {
                    setSpartid(parseInt(refSpartidRange.current.value))
                  }}
                  value={spartid}
                  aria-labelledby="spartid-label"
                  // aria-controls="spartid"
                />
              </div>
            </div>
          </div>
          <div className="text-center flex flex-col items-center md:pt-16 pt-10">
            <div aria-live="polite">
              <strong className="block text-3xl font-bold mb-6" id="result">
                Resultat
              </strong>
              <span
                className="bg-purple-600 font-bold inline-block mb-8 md:mb-12 px-6 py-4 text-4xl md:text-5xl"                
              >
                {resultat.toLocaleString('sv-SE', {
                  currency: 'SEK',
                  style: 'currency',
                })}
              </span>
            </div>
            <span className="text-xl">
              Om du sparar pengarna i plånboken har du{' '}
              {savings.toLocaleString('sv-SE', {
                currency: 'SEK',
                style: 'currency',
              })}{' '}
              om {spartid} år.
            </span>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Calculator
